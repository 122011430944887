var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-2",attrs:{"justify":"start","no-gutters":""}},[_c('v-col',{staticClass:"mt-3",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"space-between","align":"center","no-gutters":""}},[_c('div',{staticClass:"display-1"},[_vm._v(_vm._s(_vm.$t('developers.users.titles.pageTitle')))])])],1),_c('v-col',{staticClass:"mt-6",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"min-height":"600px"},attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.totalItems,"options":_vm.pagination,"loading":_vm.loading,"items-per-page":20,"sort-by":"lastName","sort-desc":false,"footer-props":{'items-per-page-options': _vm.itemsPerPage}},on:{"update:options":function($event){_vm.pagination=$event},"click:row":_vm.showEditDialogCustom},scopedSlots:_vm._u([{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("truncate")(item.firstName))+" ")]}},{key:"item.lastName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("truncate")(item.lastName))+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("truncate")(item.email))+" ")]}},{key:"item.callNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.callNumber)+" ")]}},{key:"item.servicesNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.servicesNumber)+" ")]}}])})],1)],1),(_vm.toggleEditDialog)?_c('DevelopersUsersEditDialog',{attrs:{"item":_vm.currentItem,"isLoading":_vm.isLoading.btnSave},on:{"submit":function () { return _vm.editCb(true); },"close":function () { return _vm.editCb(false); }},model:{value:(_vm.toggleEditDialog),callback:function ($$v) {_vm.toggleEditDialog=$$v},expression:"toggleEditDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }